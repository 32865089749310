<template>
    <div>
        <div
            class="legend"
            :class="{'quarter': size === 'quarter'}">
            <div
                v-for="(metric, i) in metricKeys"
                :key="`metric-${i+1}`"
                class="metric">
                <span :class="`metric-${i+1}`"><span class="metric-name">{{ metrics[metric].name }}</span></span>
            </div>
        </div>
        <apex-chart
            ref="bar"
            :height="chartHeight"
            :options="options"
            :series="series" />
    </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';
import { numeralFormat } from '@/helpers/numeralFormats';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        ApexChart
    },
    props: {
        metrics: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            required: true
        },
        channel: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            options: {
                chart: {
                    type: 'bar',
                    toolbar: { show: false }
                },
                legend: { show: false },
                colors: ['#7EDDFE', '#007FB8', '#E350B4', '#DE706A', '#F1BC57', '#856ADE', '#D7DE6A', '#6ADEBB' ],
                grid: {
                    borderColor: '#CCCCCC',
                    strokeDashArray: 4,
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        borderRadius: 3,
                        borderRadiusApplication: 'end',
                        barHeight: '80%',
                        distributed: true,
                        dataLabels: {
                            position: 'bottom'
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ['#FFFFFF']
                    },
                    offsetX: 0,
                    textAnchor: 'start',
                    dropShadow: {
                        enabled: true
                    },
                    formatter: (value, options) => { return this.formatMetric(value, options, options.dataPointIndex) }
                },
                xaxis: {
                    categories: Object.keys(this.metrics).map(key => {
                        return this.metrics[key].name;
                    })
                },
                yaxis: {
                    labels: {
                        show: true
                    }
                },
                tooltip: {
                    y: {
                        title: {
                            formatter() { return '' }
                        }
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters('metrics/facebook/ads', {
            FacebookAdvertisingTotals: 'metricsTotalsFormatted',
        }),
        ...mapState('metrics/snapchat/ads', {
            SnapchatAdvertisingTotals: state => state.metricsTotals.stats,
        }),
        ...mapState('metrics/tiktok/ads', {
            TiktokAdvertisingTotals: state => state.metricsTotals.stats,
        }),
        ...mapState('metrics/pinterest/ads', {
            PinterestAdvertisingTotals: state => state.metricsTotals.stats,
        }),
        chartHeight() {
            switch (this.size) {
                case 'full':
                    return 550;
                default:
                    return 270;
            }
        },
        metricKeys() {
            return Object.keys(this.metrics);
        },
        series() {
            const data = [];
            const formats = [];
            this.metricKeys.map(key => {
                let nums = this.metrics[key].series.map(key => key[1]);
                const total = nums.reduce((a, b) => a + b, 0);
                if (this.metrics[key].format === 'percent') {
                    data.push(+(this[this.channel + 'AdvertisingTotals'][key].value * 100).toFixed(2));
                } else if (this.metrics[key].format === 'currency') {
                    let currencyValue = this[this.channel + 'AdvertisingTotals'][key].value;
                    if (currencyValue === null) { currencyValue = 0 }
                    data.push(+currencyValue.toFixed(2));
                } else {
                    data.push(Math.round(total));
                }
                const format = this.metrics[key].format;
                formats.push(format);
            });
            return [{ data, name: formats }];
        }
    },
    watch: {
        metrics() {
            this.$refs.bar.updateOptions({
                xaxis: {
                    categories: Object.keys(this.metrics).map(key => {
                        return this.metrics[key].name;
                    })
                }
            });
        }
    },
    methods: {
        formatMetric(value, options, index) {
            const format = options.config.series[0].name[index];
            if (format) { return numeralFormat(value, format) }
            return value;
        }
    }
};
</script>

<style lang="scss" scoped>
.legend {
    display: flex;
    justify-content: center;
    transform: translateY(15px);
    &.quarter {
        flex-flow: row wrap;
        .metric {
            max-width: 50%;
        }
    }
    .metric-1,
    .metric-2,
    .metric-3,
    .metric-4,
    .metric-5,
    .metric-6,
    .metric-7,
    .metric-8 {
        display: block;
        white-space: nowrap;
        font-size: 11px;
        margin-right: 30px;
        position: relative;
        .metric-name::after {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #7EDDFE;
            top: 3px;
            left: -15px;
        }
    }
    .metric-2 .metric-name::after {
        background: #007FB8;
    }
    .metric-3 .metric-name::after {
        background: #E350B4;
    }
    .metric-4 .metric-name::after {
        background: #DE706A;
    }
    .metric-5 .metric-name::after {
        background: #F1BC57;
    }
    .metric-6 .metric-name::after {
        background: #856ADE;
    }
    .metric-7 .metric-name::after {
        background: #D7DE6A;
    }
    .metric-8 .metric-name::after {
        background: #6ADEBB;
    }
}
</style>