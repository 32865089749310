import getNumberFormats from '@/helpers/numberFormats';
import numeral from 'numeral';

export const numeralFormat = (v, format) => {
    if (format === 'currency' ) {
        return numeral(v).format(getNumberFormats().currency);
    }
    if (format === 'percent' ) {
        return `${v}%`;
    }
    return v;
};

export const numeralFormatWithMinValue = (v, format) => {
    if (format === 'currency' ) {
        if (v === Number.MIN_VALUE) return 0;
        return numeral(v).format(getNumberFormats().currency);
    }
    if (format === 'percent' ) {
        if (v === Number.MIN_VALUE) return 0;
        return numeral(v).format(getNumberFormats().percent);
    }
    return v;
};
